/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/
* {
	scrollbar-color: $main hsla(0, 0%, 100%, 0);
	scrollbar-width: thin;
}
*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	outline: none;
	padding: 0;
	margin: 0;
}

::-webkit-scrollbar {
	width: 6px;
}
::-webkit-scrollbar-track {
	visibility: hidden;
	display: none;
}
::-webkit-scrollbar-thumb {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
	transition: all 0.3s ease;
	background: $main;
	border-radius: 2px;
	&:hover {
		background: $red;
	}
}
::-webkit-scrollbar-thumb:window-inactive {
	transition: all 0.3s ease;
	background: #767993;
}

html {
	--antd-wave-shadow-color: $main;
	font-family: sans-serif;
	scroll-behavior: smooth;
}
body {
	font-family: -apple-system, BlinkMacSystemFont, 'Mates', 'Courier New', 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';	
	font-family: 'Inter', 'Mates', Arial, sans-serif;
	background: $white;
	line-height: 1.37;
	color: #020b1e; //$dark_blue;
	font-size: 16px;
}
a,
a:visited {
	transition: all 0.2s ease-in;
	text-decoration: none;
	display: inline-block;
	color:$dark_blue;
	cursor: pointer;
}
a:hover {
	text-decoration: none;
	color: $blue;
}
a:focus {
	outline: none;
}
b,
strong {
	font-weight: 500;
	display: inline;
}
p,
ol,
ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
img {
	max-width: 100%;
	display: block;
	margin: 0 auto;
	border: 0;
}
span {
	display: inline-block;
}

nav,
aside,
footer,
header,
section,
article {
	position: relative;
	display: block;
}
input,
button,
select,
textarea,
optgroup {
	font-family: 'Inter', Mates, Arial, sans-serif;
	color: $dark_blue;
}
input,
select,
textarea {
	transition: all 0.25s ease;
	border: 1px solid #ddd;
	border-radius: 6px;
	font-size: 18px;
	width:100%;
}
form {
	padding: 0;
	margin: 0;
}
label {
	font-weight: 400;
	cursor: pointer;
	display: block;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Inter', Arial, sans-serif;	
	position: relative;
	font-weight: 400;
	line-height: 1.1;
	color: $blue;
	padding: 0;
	margin: 0;
}
h1 {
	font-weight: 900;
	font-size: 56px;
	line-height: 1;
}
h2 {
	font-weight: 400;
	font-size: 42px;
	span {
		display: block;
	}
}
h3 {
	font-size: 20px;
}

h1, h2, h3 {
	sup {
		font-size: 75%;
	}
}

hr {
	height: 0;
	border: 0;
	width: 100%;
	overflow: hidden;
	box-sizing: content-box;
	border-top: 1px solid #3a378e;
	border-bottom: 1px solid #1d163c;
}
pre {
	border: 1px solid #ddd;
	margin: 20px 0;
	padding: 10px;
}

:focus,
:active {
	outline: 0;
}
::-webkit-input-placeholder {
	opacity: 1;
}
:-ms-input-placeholder {
	opacity: 1;
}
::-moz-placeholder {
	opacity: 1;
}
:-moz-placeholder {
	opacity: 1;
}

::-moz-selection {
	background: $red;
	text-shadow: none;
	color: $white;
}
::selection {
	background: $red;
	text-shadow: none;
	color: $white;
}


/** Buttons */
.button,
a.button,
button.button {
	transition: all 0.6s ease;
	text-transform: uppercase;
	white-space: nowrap;
	position: relative;
	border-radius: 5px;
	text-align: center;
	line-height: 54px;
	background:$main;
	appearance: none;
	font-weight: 500;
	padding: 0 15px;
	font-size: 13px;
	cursor: pointer;
	color: $white;
	height: 54px;
	outline: 0;
	border: 0;
	&:hover {
		background-color: $red;
		color: $white;
	}
	&:active {
		background: $main;
		color: $white;
		outline: none;
	}
	&.round {
		border-radius: 33px;
	}
	&.large {
		line-height: 85px;
		height: 85px;
		width: 280px;
	}
	&.small {
		border-radius: 20px;
		line-height: 30px;
		height: 30px;
	}
}

@media screen and (max-width: 1024px) {
	body {
		font-size: 16px;
	}
	h1 {
		font-size: 64px;
		line-height: 1;
	}
	h2 {
		font-size: 28px;
	}
	h3 {
		font-size: 18px;
	}
}
@media screen and (max-width: 768px) {
	h1 {
		font-size: 58px;
	}
	h2 {
		font-weight: 700;
	}
	h3 {
		font-size: 17px;
	}
}

@media screen and (max-width: 568px) {
	body {
		font-size: 15px;
		font-weight: 400;
	}
	h1 {
		font-size: 46px;
	}
	h2 {
		font-size: 19px;
	}
	h3 {
		font-size: 16px;
	}
}

// @media screen and (max-width: 1024px) {}
// @media screen and (max-width: 768px) {}
// @media screen and (max-width: 568px) {}
