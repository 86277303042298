.page-template {
}

.page-banner {
	border-bottom: 1px solid #dfdfdf;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-size: cover;
	.container-inner {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding: 160px 30px 60px;
		height: 100vh;
		z-index: 10;
	}

	&::after {
		background: rgba(33, 20, 95, 0.45);
		position: absolute;
		display: none;
		height: 100%;
		width: 100%;
		content: '';
		z-index: 1;
		bottom: 0;
		right: 0;
		left: 0;
		top: 0;
	}

	.content {
		padding: 60px 0;
		.stitle {
			margin: 0 0 25px;
		}
		h1 {
			line-height: 1.1;
			span {
				color: $red;
				font-size: 46px;
				display: block;
			}
		}
		.desc {
			letter-spacing: 0.003em;
			margin: 30px 0 0 90px;
			line-height: 1.3;
			font-weight: 300;
			font-size: 18px;
			width: 600px;
			p {
				padding: 10px 0;
			}
			.button {
				transition: all 0.8s ease;
				line-height: 85px;
				margin: 70px 0 0;
				height: 85px;
				width: 280px;
			}
		}
	}

	.nav {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		.item {
			position: relative;
			align-self: flex-end;
			padding: 20px 0 20px 120px;
			a {
				text-transform: uppercase;
				position: relative;
				padding: 50px 0 0;
				line-height: 1.4;
				font-weight: 500;
				min-width: 120px;
				font-size: 16px;
				display: block;
				color: $main;
				&::before {
					transition: all 0.3s ease;
					background: $red;
					position: absolute;
					display: block;
					content: '';
					width: 100%;
					height: 2px;
					top: 35px;
					left: 0;
				}
				span {
					position: absolute;
					margin: 0 0 10px;
					font-weight: 500;
					font-size: 21px;
					display: block;
					left: 0;
					top: 0;
				}
				&:hover {
					color: $red;
					&::before {
						height: 5px;
					}
				}
			}
			&:first-child {
				padding-left: 0;
			}
		}
	}

	&.cw {
		&::after {
			display: block;
		}
		color: $white;
		h1,
		.stitle {
			color: $white;
		}
		.nav .item a {
			color: $white;
		}
	}

	&.small {
		.container-inner {
			height: auto;
		}
		border-bottom: 0;
		.content {
			//h1 {font-size: 48px;}
			.desc {
				// margin: 40px 0 0 auto;
				margin: 30px auto 0 0;
				font-weight: 300;
				line-height: 1.1;
				font-size: 22px;
				// width: 340px;
			}
		}
	}
}

@media screen and (max-width: 1280px) {
	.page-banner {
		.container-inner {
			padding-top: 160px;
			padding-bottom: 60px;
		}
		.content {
			padding: 60px 0;
		}
		.nav {
			display: none;
		}
		&.small .content {
			align-items: center;
			h1 {
				font-size: 56px;
			}
			.desc {
				margin: auto 0 0;
				line-height: 1.3;
				font-size: 18px;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.page-banner {
		.container-inner {
			padding: 150px 15px 50px 50px;
		}
		&.small {
			.container-inner {
				padding: 160px 15px 60px;
			}
			.content {
				padding: 30px 0;
				.desc {
					padding: 25px 0 0;
					width: 100%;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.page-banner {
		.container-inner,
		&.small .container-inner {
			justify-content: center;
			padding: 140px 15px 40px;
		}

		.content {
			margin: 0 auto 0 0;
			max-width: 550px;
			padding: 0;
			.stitle {
				margin: 0 0 20px;
			}
			.desc {
				margin: 30px 0 0;
				font-weight: 400;
				width: auto;
				.button {
					margin: 40px 0 0;
					width: 200px;
					&.large {
						line-height: 52px;
						height: 52px;
					}
				}
			}
		}

		&.small .content {
			h1 {
				font-size: 42px;
			}
			.desc {
				max-width: 500px;
			}
		}
	}
}

@media screen and (max-width: 568px) {
	.page-banner {
		.container-inner,
		&.small .container-inner {
			justify-content: center;
			padding: 130px 15px 30px;
		}

		.content .desc {
			font-size: 15px;
			margin-left: 0;
			br {
				display: none;
			}
		}
		&.small .content {
			padding: 0 25px;
			h1 {
				font-size: 32px;
			}
		}
	}
}
