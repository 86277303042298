.red {
	color: $red;
}
.gray {
	color: $grey;
}
.purple {
	background: $main;
}
.ta-l {
	text-align: left;
}
.ta-r {
	text-align: right;
}
.upper {
	text-transform: uppercase;
}
.lower {
	text-transform: lowercase;
}

.sections {
	position: relative;
	z-index: 10;
}
.container {
	background-color: $white;
	overflow-x: hidden;
	position: relative;
}
.container-inner {
	padding: 100px 60px;
	position: relative;
	max-width: 1280px;
	margin: 0 auto;
}

.row {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
	&.sticky {
		justify-content: flex-start;
	}
	&.vertical {
		flex-direction: column;
		justify-content: stretch;
		align-items: stretch;
	}
}

.target {
	margin-top: -140px;
	float: left;
}

.heading {
	text-transform: uppercase;
	padding: 0 0 50px;
	h2 {
		margin: 0 0 10px;
		font-weight: 700;
		color: $blue;
	}
	p {
		font-weight: 400;
		font-size: 22px;
	}
}

.small {
	line-height: 1.37;
	font-weight: 300;
	color: #444657;
	font-size: 14px;
}

@media screen and (max-width: 1280px) {
	.container-inner {
		padding: 70px 30px;
		.target {
			margin-top: -110px;
		}
	}
}
@media screen and (max-width: 1024px) {
	.container-inner {
		padding: 50px 15px;
		.target {
			margin-top: -90px;
		}
	}
}
@media screen and (max-width: 768px) {
	.container-inner {
		padding: 35px 15px;
	}
	.heading {
		padding: 0 0 25px;
	}
}
@media screen and (max-width: 668px) {
	.container-inner .target {
		margin-top: -140px;
	}
	.heading p {
		font-size: 18px;
	}
}
