/* Colors */

$black: #000;
$white: #fff;

$main: #3838A0;
$red: #F59475;
$green: #0b8f1f;
$grey: #F4F5F7;
$blue: #0067BB;
$light_blue: #5E6C8A;
$dark_blue: #0C204D;