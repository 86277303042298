.footer {
	font-size: 14px;
	.container-inner {
		padding-top: 32px;
		padding-bottom: 32px;
	}
	.row {
		justify-content: flex-start;
	}
	.terms a {
		text-decoration: underline;
		margin: 0 0 0 25px;
		font-weight: 400;
		opacity: 0.64;
		&:hover,
		&.active {
			text-decoration: none;
			opacity: 1;
		}
	}
	.bottom {
		border-top: 1px solid #dedede;
		margin: 20px 0 0;
		padding: 20px 0 0;
		font-size: 12px;
		opacity: 0.62;
	}
}

@media screen and (max-width: 1280px) {
	.footer .container-inner {
		padding: 30px;
	}
}

@media screen and (max-width: 1024px) {
	.footer .container-inner {
		padding: 30px 15px;
	}
}
