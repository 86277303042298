.page-main {
	.page-banner {
		background: $main;
		&::after {
			background:rgba(56, 56, 160,0.3);
			position: absolute;
			display: block;
			content: '';
			height:100%;
			width:100%;
			z-index: 1;
			left:0;
			top:0;
		}
		.container-inner {
			min-height: 100vh;
			overflow: hidden;
			height: auto;
		}
		h1 {
			text-shadow: 0 0 1px rgba(0,0,0,0.9);
			margin: auto auto 50px 50px;
			text-transform: uppercase;
			letter-spacing: -0.03em;
			font-family: 'Inter';
			text-align: left;
			font-weight: 900;
			font-size: 118px;
			line-height: 1;
			color: #fff;
			z-index: 2;
		}
	}

	.row.head {
		flex-direction: row;
		align-items: center;
	}

	.block-about {
		background: $grey;
		.container-inner {
			padding-bottom: 0 !important;
		}
		.content {
			align-items: center;
			align-items: flex-end;
			.biocad, .desc {
				width: 50%;
			}
			.biocad {
				padding:0 40px;
			}
			.desc {
				padding:0 0 30px 60px;
				position: relative;
				line-height: 28px;
				font-size: 17px;
				&::before {
					background: $green;
					position: absolute;
					content: '';
					width: 40px;
					height: 1px;
					top: 14px;
					left:0;
				}
			}
		}
	}

	.block-export {
		.content {
			ul {
				column-count: 3;
				width: 100%;
				li {
					border-bottom: 1px solid #e8e8ec;
					padding: 22px 0 12px;
					line-height: 20px;
					span {
						color: $green;
					}
				}
			}
		}
	}

	.block-looking {
		background: $grey;
		.content {
			margin: -20px;
		}
		.item {
			padding: 20px;
			width: 50%;
			.icon {
				background-color: #fff;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				border-radius: 100px;
				height: 80px;
				width: 80px;
				&.ic1 {
					background-image: url('../../images/svg/looking-ic1.svg');
				}
				&.ic2 {
					background-image: url('../../images/svg/looking-ic2.svg');
				}
				&.ic3 {
					background-image: url('../../images/svg/looking-ic3.svg');
				}
				&.ic4 {
					background-image: url('../../images/svg/looking-ic4.svg');
				}
			}
			.title {
				padding: 24px 0 12px;
				font-weight: 700;
				font-size: 22px;
			}
		}
	}

	.block-contacts {
		background: url('../../images/contacts.svg') no-repeat 50% 50%;
		.container-inner {
			padding-bottom: 0;
		}
		.content {
			margin: 0 0 80px;
			.planet,
			.desc {
				align-items: center;
				width: 42%;
			}
			h3 {
				color: $dark_blue;
				margin: 0 0 24px;
				max-width: 460px;
				font-weight: 700;
				font-size: 24px;
			}
			.desc {
				h3 {
					font-size: 18px;
					&::before {
						background: $blue;
						position: absolute;
						content: '';
						width: 60px;
						height: 1px;
						left: -100px;
						top: 50%;
					}
				}
				.line {
					padding: 12px 0;
					p span {
						font-weight: 700;
						color: $blue;
						&.lb {
							color: $light_blue;
							font-weight: 400;
						}
					}
					a {
						text-decoration: underline;
						margin: 0 0 0 10px;
						color: $dark_blue;
						&:hover {
							text-decoration: none;
							color: $blue;
						}
					}
				}
				.address {
					border-top: 1px solid #4d4db1;
					padding: 25px 0 0;
					margin: 12px 0 0;
				}
			}
		}
		.bottom {
			background: $blue;
			border-radius: 16px;
			padding: 24px 30px;
			font-weight: 500;
			font-size: 17px;
			color: $white;
		}
	}
}

@media screen and (max-width: 1024px) {
	.page-main {
		.page-banner h1 {
			margin: auto auto 30px 30px;
			font-size: 88px;
		}

		.block-about {
			.heading h2 {
				line-height: 1.15;
				font-size: 28px;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.page-main {
		.block-about {
			.heading h2 {
				font-size: 22px;
			}
			.content {
				.biocad,
				.desc {
					width: 100%;
				}
				.biocad {
					padding: 0 10%;
					order: 2;
				}
			}
		}
		.block-export .content ul {
			column-count: 2;
		}
		.block-looking .item {
			flex-direction: column;
			display: flex;
			width: 100%;
			.desc {
				font-size: 16px;
			}
		}
		.block-contacts .content {
			justify-content: space-between;
			margin: 0 0 40px;
			.planet,
			.desc {
				font-size: 16px;
				width: 48%;
				margin: 0;
			}
			.planet {
				border-radius: 15px;
			}
		}
	}
}


@media screen and (max-width: 568px) {
	.page-main {
		.page-banner h1 {
				margin: auto auto 20% 5%;
				font-size: 56px;
		}

		.block-about {
			.heading h2 {
				font-size: 19px;
			}
			.content .desc {
				line-height: 24px;
				font-size: 16px;
			}
		}

		.block-export .content ul {
			column-count: 1;
			li:last-child {
				border:0;
			}
		}

		.block-contacts {
			.content {
				margin: 0;
				.planet,
				.desc {
					width: 100%;
					padding: 0;
				}
				.desc h3 {
					font-weight: 700;
					font-size: 17px;
				}
			}
			.bottom {
				border-radius: 15px;
				font-size: 16px;
				padding: 15px;
			}
		}
	}
}

@media screen and (max-width: 568px) {
	.page-main .page-banner h1 {
			font-size: 46px;		
	}
}
