.page-error {
	background: url('../../images/svg/404.svg') no-repeat 50% 0;
	height: calc(100vh - 120px);
	justify-content: flex-end;
	flex-direction: column;
	padding: 100px 15px;
	text-align: center;
	display: flex;
	h1 {
		height: 0;
		width: 0;
		overflow: hidden;
		visibility: hidden;
	}
	p {
		padding: 25px 0 50px;
		text-transform: uppercase;
		color: $main;
		font-weight: 300;
		font-size: 22px;
	}
	.button {
		width: 280px;
		height: 80px;
	}
	.content {
		display: flex;
		align-items: center;
		align-self: flex-end;
		flex-direction: column;
		justify-content: center;
		margin: auto auto 0;
	}
}
@media screen and (max-width: 1200px) {
	.page-error {
		background-position: 50% 20%;
		background-size: contain;
		.button {
			width: 220px;
			height: 60px;
		}
		p {
			font-size: 18px;
		}
		.content {
			margin-bottom: 50px;
		}
	}
}
@media screen and (max-width: 1200px) {
	.page-error {
		background-position: 50% 0;
	}
}
