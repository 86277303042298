.header {
	border-bottom: 1px solid rgba(166, 166, 166, 0.35);
	background-color: transparent;
	position: fixed;
	z-index: 1000;
	width: 100%;
	left: 0;
	top: 0;
	.container-inner {
		align-items: center;
		padding: 24px 30px;
	}
	.logo {
		background: url('../../images/logo.svg') no-repeat 0 0 / cover;
		height: 48px;
		width: 140px;
		margin: 0 auto;
		order: 2;
	}

	.menu {
		order: 1;
		li {
			text-transform: uppercase;
			position: relative;
			padding: 5px 3px;
			font-weight: 800;
			font-size: 14px;
			height: 40px;
			a {
				border-radius: 15px;
				position: relative;
				line-height: 30px;
				padding: 0 12px;
				display: block;
				&:hover,
				&:active,
				&.active {
					background: $blue;
					color: $white;
				}
			}
		}
	}

	.right {
		margin: 0 0 0 auto;
		order: 3;
		.button {
			background-color: $blue;
			border-radius: 25px;
			line-height: 48px;
			font-weight: 800;
			font-size: 14px;
			padding: 0 20px;
			height: 48px;
			&:hover,
			&:active {
				background-color: $dark_blue;
				color: $white;
			}
		}
	}

	&.white {
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
		border-bottom: 1px solid #ddd;
		background: $white;
		.logo {
			background-image: url('../../images/logo-blue.svg');
		}
	}
	&.sticky {
		transition: all 0.18s ease;
		border-bottom: 1px solid #ddd;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
		background: $white;
		top: 0;
		.container-inner {
			border-color: transparent;
			padding: 18px 30px;
		}
		.logo {
			background-image: url('../../images/logo-blue.svg');
		}
	}
}

@media screen and (max-width: 1024px) {
	.header {
		.container-inner {
			padding: 15px !important;
		}
	}
}

@media screen and (max-width: 768px) {
	.header {
		.logo {
			margin: 0 auto 0 0;
			order: 1;
		}
		.menu {
			margin: 0 0 0 auto;
			order: 2;
		}
		.right {
			margin: 0 0 0 6px;
		}
	}
}

@media screen and (max-width: 668px) {
	.header {
		.logo {
			background-size: cover;
			margin: 0 auto 0 0;
			height: 40px;
			width: 117px;
		}
		.menu {
			border-top: 1px solid #dedede;
			justify-content: flex-start;
			margin: 15px auto -5px 0;
			padding: 10px 0 0;
			width: 100%;
			order: 3;
			li {
				text-transform: none;
				padding: 0 12px 0 0;
				font-size: 13px;
				height: 36px;
				a {
					border-radius: 4px;
					line-height: 36px;
				}
			}
		}
		.right {
			margin: 0 0 0 auto;
			order: 2;
			.button {
				text-transform: none;
				line-height: 40px;
				font-weight: 600;
				font-size: 14px;
				height: 40px;
			}
		}
	}
}

@media screen and (max-width: 411px) {
	.header .menu {
		margin: 15px auto -7px 0;
		padding: 8px 0 0;
		li {
			padding-right: 10px;
			font-weight: 500;
			height: 32px;
			a {
				line-height: 32px;
				padding: 0 10px;
			}
		}
	}
}
