.page-content {
	padding: 120px 0 0;
	h1 {
		font-size: 46px;
		span {
			font-weight: 500;
			font-size: 28px;
			color: #777;
		}
	}
	.content {
		padding: 40px 0 0;
		font-size: 18px;
		color: #000;
		width: 100%;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: 500;
			color: #000;
		}

		h2,
		h3 {
			padding: 20px 0 10px;
			font-weight: 500;
			font-size: 24px;
		}
		h4 {
			font-size: 21px;
		}
		p {
			padding: 10px 0;
		}
		a,
		a:visited {
			font-weight: 500;
			color: $main;
			&:hover,
			&:active {
				color: #000;
			}
		}
		ol,
		ul {
			padding: 10px;
			list-style: none;
			li {
				position: relative;
				padding: 5px 0 5px 30px;
				&::before {
					position: absolute;
					content: '';
					top: 15px;
					left: 0;
				}
			}
		}
		ul li::before {
			background: $red;
			border-radius: 50%;
			height: 5px;
			width: 5px;
			top: 16px;
		}
		ol {
			counter-reset: ol-counter;
			li {
				counter-increment: ol-counter;				
				&::before {
					content: counter(ol-counter) '. ';
					text-align: right;
					font-weight: 500;
					color: $main;
					width:20px;
					top: 5px;
				}
			}
		}
	}
}
